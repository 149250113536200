<div class="container" *ngIf="!isImage && !showWebcam">
    <div class="row">
        <div class="col-12 text-center pt-5">
            <img class="demo-img" src="./../../assets/images/demo.png">
        </div>
        <div class="col-12 text-center pt-3 mobile">
            <button class="btn btn-upload" (click)="startWebcam()">
                <i class="fa fa-camera" aria-hidden="true"></i> Capture Photo
            </button>
        </div>
        <div class="col-12 text-center pt-2">
            <button class="btn btn-upload">
                <input id="imgFile" type="file" (change)="changeImage($event)" accept="image/*">
                <i class="fa fa-cloud-upload" aria-hidden="true"></i> Upload Photo
            </button>
        </div>
    </div>
</div>

<div class="container" *ngIf="showWebcam">
    <div class="row">
        <div class="col-12 text-center pt-5">
            <div class="camera-div">
                <webcam [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
                    [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
                    [videoOptions]="videoOptions" [imageQuality]="1" (cameraSwitched)="cameraWasSwitched($event)"
                    (initError)="handleInitError($event)"></webcam>
            </div>
            <button class="btn btn-upload" (click)="triggerSnapshot()">CAPTURE IMAGE</button>
        </div>
    </div>
</div>

<div class="container web" *ngIf="isImage && !loading && !isError">
    <div class="row py-5 shade-div">
        <div class="col-6 text-center py-4">
            <img class="data-img mb-2" [src]="url">
            <button class="btn btn-upload mx-auto mt-2 mobile" (click)="startWebcam()">
                <i class="fa fa-camera" aria-hidden="true"></i> Capture Photo
            </button>
            <button class="btn btn-upload mt-1">
                <input id="imgFile" type="file" (change)="changeImage($event)" accept="image/*">
                <i class="fa fa-cloud-upload" aria-hidden="true"></i> Change Photo
            </button>
        </div>
        <div class="col-6 p-5 m-auto">
            <!-- <div class="row">
                <div class="col-6">
                    <h3>Dullness Score : <span class="skintone-text">{{ outputData.dullness_score}}</span></h3>
                </div>
                <div class="col-6">
                    <h3>Uneven Score : <span class="skintone-text">{{ outputData.uneven_score}}</span></h3>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h3>Detected Skin Tone</h3>
                </div>
            </div> -->
            <div class="row">
                <div class="col pt-3 mx-auto">
                    <div class="hair-color" [ngStyle]="{'background': hairColor}"></div>
                    <!-- <button class="btn-foundation" id="btn0"
                        [style.backgroundImage]="'url(' + outputImages.skin_tone_palette + ')'"
                        (click)="onClick($event)"></button> -->
                </div>
                <!-- <div class="col-10 pt-3 mx-auto">
                    <h4 class="skintone-text">{{ detectedSkintone }}</h4>
                </div> -->
            </div>
            <div class="row pt-5">
                <div class="col mx-auto text-center">
                    <h3>Detected Hair Color</h3>
                </div>
            </div>
            <!--
            <div class="row">
                <div class="col-3 pt-3">
                    <button class="btn-foundation ml-2" id="btn1"
                        [style.backgroundImage]="'url(' + outputImages.palette1 + ')'"
                        (click)="onClick($event)"></button>
                    <br>
                    <label> {{outputData.recommendedTone1}} </label>
                </div>
                <div class="col-3 pt-3">  ml-2 -->
            <!-- <button class="btn-foundation ml-2" id="btn2"  
                        [style.backgroundImage]="'url(' + outputImages.palette2 + ')'"
                        (click)="onClick($event)"></button>
                        <label> {{outputData.recommendedTone2}} </label>
                </div>
                <div class="col-3 pt-3">
                    <button class="btn-foundation ml-2" id="btn3"
                        [style.backgroundImage]="'url(' + outputImages.palette3 + ')'"
                        (click)="onClick($event)"></button>
                        <label> {{outputData.recommendedTone3}} </label>
                </div>
                <div class="col-3 pt-3">
                    <button class="btn-foundation ml-2" id="btn4"
                        [style.backgroundImage]="'url(' + outputImages.palette4 + ')'"
                        (click)="onClick($event)"></button>
                    <label> {{outputData.recommendedTone4}} </label>
                </div> -->
            <!-- </div> -->
        </div>
    </div>
</div>

<div class="container web" *ngIf="isError">
    <div class="row">
        <div class="col-12 text-center shade-div p-5">
            <h3 class="text-danger">{{ error }}</h3>
            <button class="btn btn-upload mt-1">
                <input id="imgFile" type="file" (change)="changeImage($event)" accept="image/*">
                <i class="fa fa-cloud-upload" aria-hidden="true"></i> Change Photo
            </button>
        </div>
    </div>
</div>

<div class="container mobile" *ngIf="isImage && !loading && !isError">
    <!-- <div class="row shade-div p-2 m-1 mt-3"> -->
    <!-- <div class="col-6">
            <h5>Dullness Score : <span class="skintone-text">{{ outputData.dullness_score}}</span></h5>
        </div>
        <div class="col-6">
            <h5>Uneven Score : <span class="skintone-text">{{ outputData.uneven_score}}</span></h5>
        </div>
        <div class="col-12">
            <h5>Detected Skin Tone</h5>
        </div> -->
    <div class="col-12">
        <div class="row">
            <div class="col text-center mx-auto">
                <div class="hair-color" [ngStyle]="{'background': hairColor}"></div>
                <!-- <button class="btn-foundation" id="btn0"
                        [style.backgroundImage]="'url(' + outputImages.skin_tone_palette + ')'"
                        (click)="onClick($event)"></button> -->
            </div>
        </div>
    </div>
    <!-- <div class="col-8 mx-auto">
                    <h5 class="skintone-text" style="margin: 20px;">{{ detectedSkintone }}</h5>
                </div> -->
    <!-- </div>
        </div>
    </div> -->
    <div class="row pt-1">
        <div class="col-12 text-center">
            <img class="data-img" [src]="url">
        </div>
    </div>
    <div class="row shade-div p-2 m-1 mt-2">
        <div class="col-12">
            <h4>Hair Color Detected!</h4>
        </div>
    </div>

    <!-- <div class="col-12 pt-3 text-center"> -->
    <!-- <div class="col-3 pt-3 text-center">
                <button class="btn-foundation" id="btn1" [style.backgroundImage]="'url(' + outputImages.palette1 + ')'"
                    (click)="onClick($event)"></button>
                <label> {{outputData.recommendedTone1}} </label>
            </div>
            <div class="col-3 pt-3 text-center">
                <button class="btn-foundation " id="btn2" [style.backgroundImage]="'url(' + outputImages.palette2 + ')'"
                (click)="onClick($event)"></button>
                <label> {{outputData.recommendedTone2}} </label>
            </div>
            <div class="col-3 pt-3 text-center">
        <button class="btn-foundation " id="btn3" [style.backgroundImage]="'url(' + outputImages.palette3 + ')'"
            (click)="onClick($event)"></button>
        <label> {{outputData.recommendedTone3}} </label>
    </div>
    <div class="col-3 pt-3 text-center">
        <button class="btn-foundation " id="btn4" [style.backgroundImage]="'url(' + outputImages.palette4 + ')'"
            (click)="onClick($event)"></button>
        <label> {{outputData.recommendedTone4}} </label>
    </div> -->
    <!-- </div> -->
    <!-- </div> -->
    <div class="row pt-1">
        <div class="col-12 text-center">
            <button class="btn btn-upload mx-auto mt-2 mobile" (click)="startWebcam()">
                <i class="fa fa-camera" aria-hidden="true"></i> Capture Photo
            </button>
            <button class="btn btn-upload mt-1">
                <input id="imgFile" type="file" (change)="changeImage($event)" accept="image/*">
                <i class="fa fa-cloud-upload" aria-hidden="true"></i> Change Photo
            </button>
        </div>
    </div>
</div>

<div class="container mobile" *ngIf="isError">
    <div class="row shade-div p-2 m-1">
        <div class="col-12 text-center">
            <h3 class="text-danger">{{ error }}</h3>
            <button class="btn btn-upload mx-auto mt-2 mobile" (click)="startWebcam()">
                <i class="fa fa-camera" aria-hidden="true"></i> Capture Photo
            </button>
            <button class="btn btn-upload mt-1">
                <input id="imgFile" type="file" (change)="changeImage($event)" accept="image/*">
                <i class="fa fa-cloud-upload" aria-hidden="true"></i> Change Photo
            </button>
        </div>
    </div>
</div>

<div class="container" *ngIf="loading">
    <div class="row">
        <div class="col-12">
            <ngx-ui-loader></ngx-ui-loader>
        </div>
    </div>
</div>